import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';

import {
    FONT_GOTHAM_MEDIUM,
    FONT_GOTHAM_LIGHT,
    TIMES_NEW_ROMAN,
} from '../../styles/fonts';
import arrowIcon from '../../images/arrow-bullet.png';
import line from '../../images/line.png';
import lineWebp from '../../images/line.webp';
import { CompatibleImg } from '../Img';
import { useMainPageData } from '../../queries/useMainPageData';
import {
    HighlightedText,
    device,
    Signature,
    HiddenLabel,
} from '../commonStyled';
import { formatTextAriaString } from '../../utils';
import { useWebpImage } from '../../hooks/useWebpImage';
import { COMPANY_NAME } from '../../constants';
import { Box } from '../Box';

const HomeHeader: React.FC = () => {
    const {
        bulletPoints,
        highlightedText,
        image,
        infoTitle,
        main_logo_text,
        sub_logo_text,
    } = useMainPageData();

    const [lineTop, setLineTop] = useState(180);
    const titleRef = useRef(null);
    const lineSrc = useWebpImage(lineWebp, line);

    useEffect(() => {
        const updateLineTop = () => {
            if (titleRef.current) {
                const styles = window?.getComputedStyle(titleRef.current);
                const margin =
                    parseFloat(styles.marginTop) +
                    parseFloat(styles.marginBottom);
                setLineTop(
                    titleRef.current?.getBoundingClientRect().height + margin
                );
            }
        };

        updateLineTop();

        window?.addEventListener('resize', updateLineTop, { passive: true });

        return () => {
            window?.removeEventListener('resize', updateLineTop);
        };
    }, []);

    return (
        <Container>
            <InfoContainer>
                <TitleSection ref={titleRef}>
                    <Title>
                        <TitleMain
                            dangerouslySetInnerHTML={{ __html: main_logo_text }}
                        />
                        {sub_logo_text ? (
                            <SignatureStyled>{sub_logo_text}</SignatureStyled>
                        ) : (
                            <Box height={60} />
                        )}
                    </Title>
                </TitleSection>
                <HighlightedTextStyled marginBottom={52}>
                    {highlightedText}
                </HighlightedTextStyled>

                <MobileViewContainer>
                    <HighlightedTextMobile>
                        {highlightedText}
                    </HighlightedTextMobile>
                    <MobileCompatibleContainer>
                        {image && (
                            <MobileImgWrap
                                itemScope
                                itemType="http://schema.org/ImageObject"
                            >
                                <HiddenLabel itemProp="name">
                                    {COMPANY_NAME}
                                </HiddenLabel>
                                <CompatibleImg
                                    itemProp="contentUrl"
                                    image={image?.localFile}
                                />
                                <HiddenLabel itemProp="description">
                                    {COMPANY_NAME}
                                </HiddenLabel>
                            </MobileImgWrap>
                        )}
                    </MobileCompatibleContainer>
                </MobileViewContainer>
                <LineMobile bgImg={lineSrc} />

                <Description
                    dangerouslySetInnerHTML={{
                        __html: formatTextAriaString(infoTitle),
                    }}
                />
                <BulletPointsContainer>
                    {Object.keys(bulletPoints).map((key) => (
                        <BulletPointItem
                            key={key}
                            dangerouslySetInnerHTML={{
                                __html: bulletPoints[key],
                            }}
                        />
                    ))}
                </BulletPointsContainer>
            </InfoContainer>
            <CompatibleContainer>
                {image && (
                    <CompatibleImgWrap
                        itemScope
                        itemType="http://schema.org/ImageObject"
                    >
                        <HiddenLabel itemProp="name">
                            {COMPANY_NAME}
                        </HiddenLabel>
                        <CompatibleImg
                            image={image?.localFile}
                            itemProp="contentUrl"
                        />
                        <HiddenLabel itemProp="description">
                            {COMPANY_NAME}
                        </HiddenLabel>
                    </CompatibleImgWrap>
                )}
            </CompatibleContainer>
            <LineDesktop bgImg={lineSrc} top={lineTop} />
        </Container>
    );
};

const MobileViewContainer = styled.div`
    position: relative;
    display: none;

    @media ${device.mobileMedium} {
        display: flex;
        height: 244px;
        margin-bottom: -26px;
        justify-content: center;
    }

    @media ${device.mobileSmall} {
        height: 230px;
    }

    @media (max-width: 360px) {
        height: 200px;
    }
`;

const HighlightedTextMobile = styled.div`
    position: relative;
    z-index: 1;
    font-family: ${FONT_GOTHAM_MEDIUM};
    color: #a60424;
    font-size: 14px;
    align-self: flex-end;
    margin-bottom: 47px;
    width: 179px;
`;

const MobileCompatibleContainer = styled.div`
    position: relative;
    max-width: 200px;
    flex-grow: 1;
    flex-shrink: 0;
    z-index: 0;
    align-self: flex-end;
`;

const MobileImgWrap = styled.div`
    position: absolute;
    left: -100px;
    right: -100px;
    bottom: 0;
    height: auto;
    flex-shrink: 0;
    z-index: 1;
    align-self: flex-end;
    overflow: hidden;
`;

const LineMobile = styled('div')<{ bgImg: string }>`
    display: none;
    width: calc(100% + 48px);
    height: 26px;
    margin: 0 -24px;
    background-image: url(${({ bgImg }) => bgImg});
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 26px;

    @media ${device.mobileMedium} {
        display: block;
    }
`;

const SignatureStyled = styled(Signature)`
    font-size: 64px;
    line-height: 60px;
    white-space: nowrap;

    @media ${device.mobileMedium} {
        font-size: 10vw;
        line-height: 10vw;
    }
`;

const HighlightedTextStyled = styled(HighlightedText)`
    position: relative;
    z-index: 2;
    @media ${device.laptopSmall} {
        justify-content: end;
        margin-bottom: 24px;
    }

    @media ${device.mobileMedium} {
        display: none;
    }
`;

const TitleMain = styled.h1`
    text-align: center;
    font-family: Roboto;
    text-transform: uppercase;
    font-size: 34px;
    white-space: nowrap;
    font-family: ${TIMES_NEW_ROMAN};

    @media ${device.mobileMedium} {
        font-size: 5vw;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TitleSection = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media ${device.laptopSmall} {
        align-items: baseline;
    }

    @media ${device.mobileMedium} {
        align-items: center;
    }

    @media ${device.mobileMedium} {
        height: auto;
        margin: 19px 0;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    overflow-x: hidden;
    margin-top: 20px;

    @media ${device.mobileMedium} {
        padding-bottom: 20px;
    }
`;

const InfoContainer = styled.div`
    position: relative;
    z-index: 2;
    margin-top: 0;
    margin-right: 80px;
    max-width: 600px;

    @media ${device.laptopMedium} {
        max-width: 450px;
        margin-left: 12px;
    }

    @media ${device.laptopSmall} {
        max-width: 400px;
        margin-right: 0px;
    }

    @media ${device.tabletMedium} {
        max-width: 300px;
        margin-right: 0px;
    }

    @media ${device.mobileMedium} {
        max-width: 100%;
        margin: 0px;
        padding: 0 12px;
    }
`;

const CompatibleContainer = styled.div`
    position: relative;
    max-width: 400px;
    flex-grow: 1;
    flex-shrink: 0;
    z-index: 1;
    align-self: flex-end;

    @media ${device.laptopSmall} {
        max-width: 300px;
    }

    @media ${device.mobileMedium} {
        display: none;
    }
`;

const CompatibleImgWrap = styled.div`
    position: absolute;
    left: -300px;
    right: -300px;
    bottom: 0;
    height: auto;
    flex-shrink: 0;
    z-index: 1;
    align-self: flex-end;

    @media ${device.laptopMedium} {
        left: -200px;
        right: -200px;
    }
`;

const Line = styled('div')<{ bgImg: string; top?: number }>`
    position: absolute;
    top: ${({ top }) => top && `${top}px`};
    left: 0;
    width: 100%;
    height: 52px;
    background-image: url(${({ bgImg }) => bgImg});
    background-repeat: no-repeat;
    background-size: cover;
`;

const LineDesktop = styled(Line)`
    @media ${device.mobileMedium} {
        display: none;
    }
`;

const Description = styled.div`
    font-family: ${FONT_GOTHAM_MEDIUM};
    color: #2e0f25;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 46px;

    @media ${device.laptopMedium} {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 26px;
    }

    @media ${device.mobileMedium} {
        font-size: 14px;
        line-height: 15px;
    }
`;

const BulletPointsContainer = styled.ul`
    margin: 0;
    padding: 0;
`;

const BulletPointItem = styled.li`
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: ${FONT_GOTHAM_LIGHT};
    padding-left: 50px;
    margin-bottom: 40px;
    line-height: 26px;
    list-style: none;

    @media ${device.laptopMedium} {
        margin-bottom: 24px;
    }

    @media ${device.mobileMedium} {
        font-size: 14px;
        line-height: 15px;
        padding-left: 30px;
        margin-bottom: 23px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 31px;
        height: 31px;
        background-image: url(${arrowIcon});

        @media ${device.mobileMedium} {
            top: 8px;
            width: 15px;
            height: 15px;
            background-position: center;
            background-size: cover;
        }
    }
`;

export default HomeHeader;
